import Box from "@mui/material/Box";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DashboardWrapper } from "src/components/common/DashboardWrapper";
import { Button } from "src/components/common/FormComponents";
import HorizontalTabs from "src/components/common/HorizontalTabs";
import MultiSelect from "src/components/common/MultiSelect";
import SearchBar from "src/components/common/SearchField";
import {
  ExportStatusMessages2,
  ProjectType,
  registartionStatus,
  registeredEntitiesTabs,
  registeredLandTabs,
  statusMessages2,
} from "src/constants/common";
import { tableColumns } from "src/constants/tableColumns";
import {
  getAllProjectsData,
  refetchAllProjects,
} from "src/redux/slices/dashboard/projects";
import {
  getAllProjects,
  getAllRegistration,
  getAllUser,
} from "src/utils/api/dashboard";
import { getStates } from "src/utils/api/roles";
import { dateAndTimeStamp, downloadSheet, formatINR } from "src/utils/helper";
import {
  RenderButton,
  RenderChip,
  RenderDemandDraft,
  RenderView,
  RenderViewDemandDraft,
} from "./RenderComponent";
import RenderTable from "./RenderTable";
import { path } from "src/constants/path";
type filterProjectType = ProjectType.TREE_PLANTATION | ProjectType.LAND_LEASED;
type RegistrationType = "registeredEntity" | "registeredLands";
const Mapping: Record<
  "BUTTON" | "CHIP" | "DEMAND_DRAFT" | "VIEW_DEMAND" | "VIEW_BUTTON",
  (params: any) => JSX.Element
> = {
  BUTTON: RenderButton,
  CHIP: RenderChip,
  DEMAND_DRAFT: RenderDemandDraft,
  VIEW_DEMAND: RenderViewDemandDraft,
  VIEW_BUTTON: RenderView,
};
const Home = ({
  registrationType = "registeredEntity",
}: {
  registrationType?: RegistrationType;
}) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [projectData, setProjectData] = useState<any>({
    count: 0,
    rows: [],
  });
  const [states, setStates] = useState<any>([]);
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("currentTab");

  const [entityActiveTab, setEntityActiveTab] = useState(tab ?? undefined);
  const [activeTab, setActiveTab] = useState(tab ?? undefined);
  const [plantationBlockData, setPlantationBlockData] = useState<any>({
    count: 0,
    rows: [],
  });

  const [queryParams, setQueryParams] = useState<{
    status: any;
    offset: number;
    limit: number;
    sortBy: string;
    searchText: string;
    projectStateIds?: string[];
    download: boolean;
    entityIds?: string[];
  }>({
    status:
      registrationType === "registeredEntity" ? activeTab : entityActiveTab,
    offset: 0,
    limit: 50,
    sortBy: "updated_at:DESC",
    searchText: "",
    projectStateIds: undefined,
    download: false,
    entityIds: undefined,
  });

  const [userQueryParams, setUserQueryParams] = useState<{
    role?: string;
    status?: string;
    offset: number;
    limit: number;
  }>({
    role: "Entity/ Green Credit Applicant",
    status: "ACTIVE",
    offset: 0,
    limit: 1000,
  });

  const [filterKey, setFilterKey] = useState({
    projectStateIds: null,
  });

  const [entityfilterKey, entitySetFilterKey] = useState({
    enetityStateIds: null,
  });

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const { isFetching: isEntityFetching } = useQuery(
    ["projects", queryParams],
    () =>
      getAllRegistration({
        ...queryParams,
        stateIds: queryParams?.projectStateIds
          ? queryParams?.projectStateIds.join(",")
          : undefined,
      }),
    {
      onSuccess: (data) => {
        dispatch(getAllProjectsData(data.data?.rows));
        const rows = data?.data?.rows?.map((row) => ({
          data: row,
          sno: row?.serialNo,
          id: row.id,
          status: row.status,
          entityName: row?.user?.firstName,
          netPlantationArea: row?.netPlantationArea ?? "-",
          registrationNo: row.registrationNo,
          state: row.state,
          district: row.district,
          division: row?.division,
          allocatedivision:
            row?.projectDetail?.organizationAffiliationDetail?.userDetail
              ?.division,
          range: row?.projectDetail?.landSpecification?.range,
          treeCanopy: row?.treeCannopyDensity ?? "-",
          landArea: row?.projectDetail?.landArea,
          areaUnit: `${row.totalArea}`,
          demandNote: formatINR(row.demandNote) ?? "-",
          estimateAmount: row.demandNote
            ? formatINR(
                +`${Number(row?.demandNote)}` + +`${Number(row?.icfreCharges)}`
              )
            : "-",
          totalAmountRecived: formatINR(row.amountPaid) ?? "-",
          ecoRestorationCostRecived: formatINR(row.ecoRestorationCost) ?? "-",
          adminChargesRecived: formatINR(row.adminCharges) ?? "-",
          remark: row.remark,
          created_at: dateAndTimeStamp(row?.created_at, "IST"),
          updated_at: dateAndTimeStamp(row.updated_at, "IST"),
        }));

        if (queryParams.download) {
          interface ExportItem {
            "S.NO"?: any;
            EPID?: any;
            "Registration No"?: any;
            "Entity Name"?: any;
            State?: any;
            District?: any;
            Division?: any;
            "Allocate Division"?: any;
            Range?: any;
            "Total Area"?: any;
            "Area Selected"?: any;
            Remark?: any;
            "Created At"?: any;
            "Updated At"?: any;
            Status?: any;
            "Final Area"?: any;
            "Tree Canopy Density"?: any;
            Eco_Restoration_Cost?: any;
            DemandNote_Amount?: any;
            "Total Amount Received"?: any;
            "Eco-Restoration Cost Received"?: any;
            "Admin Charges Received"?: any;
          }

          const exportData: ExportItem[] = rows.map((item) => {
            const status = statusMessages2[item?.status] ?? item?.status;
            const data: ExportItem = {
              "S.NO": item?.sno,
              EPID: item?.id,
              "Registration No": item?.registrationNo,
              "Entity Name": item?.entityName,
              State: item?.state,
              District: item?.district,
              Division: item?.division,
              "Allocate Division": item?.allocatedivision,
              Range: item?.range,
              "Tree Canopy Density": item?.treeCanopy,
              "Total Area": item?.landArea,
              "Area Selected": item?.areaUnit,
              "Final Area": item?.netPlantationArea,
              "Created At": item?.created_at,
              "Updated At": item?.updated_at,
              "Total Amount Received": item?.totalAmountRecived,
              "Eco-Restoration Cost Received": item.ecoRestorationCostRecived,
              "Admin Charges Received": item.adminChargesRecived,
            };

            if (status === "UNDER_QUERY" || status === "VERIFICATION_PENDING") {
              // Exclude eco_restoration_cost and demandNote_Amount
              data.Eco_Restoration_Cost = "-";
              data.DemandNote_Amount = "-";
            } else {
              // Include eco_restoration_cost and demandNote_Amount
              data.Eco_Restoration_Cost = item?.demandNote ?? "-";
              data.DemandNote_Amount = item?.estimateAmount ?? "-";
            }

            data.Status = ExportStatusMessages2[item?.status] ?? item?.status;
            data.Remark = item?.remark;

            return data;
          });

          downloadSheet(exportData);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setProjectData({
            count: data?.data.count,
            rows,
          });
        }
      },
      refetchOnMount: true,
      enabled: registrationType === "registeredEntity",
    }
  );

  const { isFetching: isPlantationFetching } = useQuery(
    ["plantationProjects", queryParams],
    () =>
      getAllProjects({
        ...queryParams,
        projectStateIds: queryParams?.projectStateIds
          ? queryParams?.projectStateIds.join(",")
          : undefined,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const rows = res?.data?.rows.map((row) => {
          const status = registartionStatus[row.status] || row.status;

          return {
            data: row,
            sno: row?.serialNo,
            id: row.id,
            registrationNo: row.registrationNo,
            state: row?.address?.stateDetail?.name,
            district: row?.address?.districtDetail?.name,
            division: row?.landSpecification?.division ?? "-",
            treeCanopy: row?.treeCannopyDensity ?? "-",
            allocatedivision:
              row?.organizationAffiliationDetail?.userDetail?.division,
            areaUnit: `${(+row.landArea).toFixed(2)}`,
            created_at: dateAndTimeStamp(row?.created_at, "IST"),
            updated_at: dateAndTimeStamp(row?.updated_at, "IST"),
            status: status,
            remark: row.remarks,
          };
        });

        if (queryParams.download) {
          const exportData = rows.map((item) => {
            return {
              "S.No": item?.sno,
              "Registration No": item?.registrationNo,
              State: item?.state,
              District: item?.district,
              Division: item?.division,
              "Tree Canopy Density": item?.treeCanopy,
              "Total Area (Ha)": item?.areaUnit,
              "Created At": item?.created_at,
              "Updated At": item?.updated_at,
              Status: item?.status,
              Remark: item?.remark,
            };
          });
          downloadSheet(exportData);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setPlantationBlockData({
            count: res.data.count,
            rows,
          });
        }
      },
      refetchOnMount: true,
      enabled: registrationType !== "registeredEntity",
    }
  );

  const { data: userData } = useQuery(
    ["users", queryParams],
    () => getAllUser(userQueryParams),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const entityInfo = userData?.data?.rows?.map((item) => {
    return {
      id: item.id,
      name: item.firstName,
    };
  });

  const columns = useMemo(() => {
    return tableColumns
      .map((item) => ({
        ...item,
        renderCell: Mapping[item.renderCell],
      }))
      .filter((item) => {
        return (
          item.type.includes(registrationType) &&
          !!!item.tab.includes(activeTab)
        );
      });
  }, [activeTab]);

  const entitiesColumns = useMemo(
    () =>
      tableColumns
        .map((item) => ({
          ...item,
          renderCell: Mapping[item.renderCell],
        }))
        .filter((item) => {
          return item.type.includes(registrationType);
        })
        .filter((item) => {
          return !!!item.tab.includes(entityActiveTab);
        }),
    [registrationType, entityActiveTab]
  );

  const handlePagination = (page: any) => {
    dispatch(refetchAllProjects(true));
    setQueryParams({
      ...queryParams,
      offset: queryParams.limit * page,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  const exportToExcel = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const exportToExcel2 = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  return (
    <DashboardWrapper
      title={
        registrationType === "registeredEntity"
          ? "Status of Cost Estimates"
          : "Eco-Restoration Blocks"
      }
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!projectData.rows.length}
    >
      {registrationType === "registeredEntity" ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "2%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <SearchBar
              placeHolder="Search by Registration Number"
              getText={(e) => {
                setQueryParams((a) => {
                  return { ...queryParams, searchText: `${e}` };
                });
              }}
            />
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    projectStateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    projectStateIds: newState?.projectStateIds,
                  });

                  return newState;
                });
              }}
            />
            <MultiSelect
              options={entityInfo}
              label={"Filter By Entity"}
              onChange={(e: any) => {
                entitySetFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    entityIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    entityIds: newState?.entityIds,
                  });

                  return newState;
                });
              }}
            />
            <Button onClick={exportToExcel2} text={"Export"} />
          </Box>
          <HorizontalTabs
            tabHeaders={registeredEntitiesTabs}
            tabIndex={registeredEntitiesTabs.findIndex(
              (item) => item.value === entityActiveTab
            )}
            onChange={(e: RegistrationType) => {
              dispatch(refetchAllProjects(true));
              setEntityActiveTab(e);
              navigate(`${path.home}${e ? `?currentTab=${e}` : ""}`);
              setQueryParams({
                ...queryParams,
                status: e,
                offset: 0,
              });
            }}
            components={[<></>, <></>]}
          />
          <RenderTable
            rows={projectData.rows}
            columns={entitiesColumns}
            handleSortModelChange={handleSort}
            hideFooterPagination={false}
            isLoading={isEntityFetching}
            rowCount={projectData?.count / queryParams.limit}
            onPageChange={(page) => handlePagination(page)}
            pageNumber={queryParams.offset / queryParams.limit + 1}
          />
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "3%",
              padding: "10px 1%",
              background: "white",
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
            }}
          >
            <SearchBar
              placeHolder="Search by Registration Number..."
              getText={(e) => {
                setQueryParams((a) => {
                  return { ...queryParams, searchText: `${e}` };
                });
              }}
            />
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    projectStateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    projectStateIds: newState?.projectStateIds,
                  });

                  return newState;
                });
              }}
            />
            <Button onClick={exportToExcel} text={"Export"} />
          </Box>
          <HorizontalTabs
            tabHeaders={registeredLandTabs}
            tabIndex={registeredLandTabs.findIndex(
              (item) => item.value === activeTab
            )}
            onChange={(e: filterProjectType) => {
              dispatch(refetchAllProjects(true));
              setActiveTab(e);
              navigate(`${path.registeredLands}${e ? `?currentTab=${e}` : ""}`);
              setQueryParams({
                ...queryParams,
                status: e,
                offset: 0,
              });
            }}
            components={[<></>, <></>]}
          />

          <RenderTable
            rows={plantationBlockData.rows}
            columns={columns}
            handleSortModelChange={handleSort}
            hideFooterPagination={false}
            isLoading={isPlantationFetching}
            rowCount={plantationBlockData?.count / queryParams.limit}
            onPageChange={(page) => handlePagination(page)}
            pageNumber={queryParams.offset / queryParams.limit + 1}
          />
        </>
      )}
    </DashboardWrapper>
  );
};

export default Home;
