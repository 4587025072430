import { Box, CircularProgress } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { createSearchParams, useNavigate } from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import { getProgressDetails } from "src/utils/api/dashboard";
import { dateAndTimeStamp, downloadSheet, formatINR } from "src/utils/helper";
import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import { getStyles } from "src/styles/theme";

const ProgressDetails = () => {
  const navigate = useNavigate();
  const [tableRows, setTableRows] = useState([]);

  const [states, setStates] = useState<any>([]);
  const [totalCostEstimateReceived, setTotalCostEstimateReceived] = useState(0);
  const [totalProgressDetailReceived, setTotalProgressReceived] = useState(0);

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [queryParams, setQueryParams] = useState<{
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
    stateIds?: string[];
    landOwnershipStatus: string;
    targetStatus?: string;
  }>({
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
    stateIds: undefined,
    landOwnershipStatus: "APPROVED",
    // targetStatus: "SUBMIT_TO_SNO",
  });

  const [filterKey, setFilterKey] = useState({
    stateIds: null,
  });

  const { isLoading, isFetching } = useQuery(
    ["users", queryParams],
    () =>
      getProgressDetails({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const sortedData = res?.data?.data?.rows.sort((a, b) => {
          // Move objects with `isDigitalCostEstimateFilled: true` to the beginning
          return b.isDigitalCostEstimateFilled - a.isDigitalCostEstimateFilled;
        });
        const row = sortedData.map((row, idx) => ({
          // sno: row?.serialNo,
          sno: idx + 1,
          id: row?.id,
          costEstimateID: row.id,
          registrationNo: row.projectDetail.registration_no,
          entityName: row.user.firstName,
          state: row?.projectDetail?.address?.stateDetail?.name,
          division: row.projectDetail.landSpecification.division,
          allocateDivision:
            row.projectDetail.organizationAffiliationDetail?.userDetail
              ?.division,
          totalArea: `${row.netPlantationArea}`,
          ecoRestorationAmount: formatINR(row?.demandNote ?? "-"),
          created_at: dateAndTimeStamp(row.created_at, "IST"),
          updated_at: dateAndTimeStamp(row.updated_at, "IST"),
          isProgressFilled: row.isProgressFilled,
          isDigitalCostEstimateFilled: row.isDigitalCostEstimateFilled,
        }));

        if (queryParams.download) {
          const exportData = row.map((item) => {
            return {
              "S.No": item?.sno,
              "Registration No": item?.registrationNo,
              "Entity Name": item.entityName,
              State: item?.state,
              "Allocate Division": item?.allocateDivision,
              Division: item?.division,
              "Net Planned Area (Ha)": item?.totalArea,
              "Eco-Restoration Cost (₹)": item?.ecoRestorationAmount,
              "Created At": item?.created_at,
              "Updated At": item?.updated_at,
            };
          });
          downloadSheet(exportData);
          setQueryParams((prev) => ({
            ...prev,
            download: false,
          }));
        } else {
          setTableRows(row);
          const total1 = row.reduce((acc: number, curr) => {
            return acc + (curr.isDigitalCostEstimateFilled ? 1 : 0);
          }, 0);
          const total2 = row.reduce((acc: number, curr) => {
            return acc + (curr.isProgressFilled ? 1 : 0);
          }, 0);
          setTotalCostEstimateReceived(total1);
          setTotalProgressReceived(total2);
        }
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const RenderEPID = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${path.costEstimateDetails}?${createSearchParams({
            id: params?.row?.costEstimateID,
          })}`}
          sx={{ ...defaultStyles.renderButtonText3 }}
        >
          <Box
            component="img"
            src={
              params?.row?.isDigitalCostEstimateFilled
                ? "/assets/svg/details.svg"
                : "/assets/svg/details_red.svg"
            }
            sx={{ ...defaultStyles.imgStyle }}
          ></Box>
        </Box>
      </>
    );
  };

  const ViewProgressDetails = (params) => {
    return (
      <>
        <Box
          component="a"
          href={`${path.advanceWorkProgress}?${createSearchParams({
            id: params?.row?.costEstimateID,
          })}`}
          sx={{ ...defaultStyles.renderButtonText3 }}
        >
          {params.row.isProgressFilled ? (
            <Box
              component="img"
              src="/assets/svg/history.svg"
              sx={{ ...defaultStyles.imgStyle }}
            ></Box>
          ) : (
            <Box
              component="img"
              src="/assets/svg/history_red.svg"
              sx={{ ...defaultStyles.imgStyle }}
            ></Box>
          )}
        </Box>
      </>
    );
  };

  const tableColumns: any = [
    {
      field: "sno",
      headerName: "S.No",
      width: 90,
      sortable: false,
    },
    {
      field: "costEstimateID",
      headerName: "Cost Estimate ID",
      width: 200,
      sortable: false,
    },
    {
      field: "registrationNo",
      headerName: "Reg.No",
      width: 100,
      sortable: false,
    },
    {
      field: "entityName",
      headerName: "Entity Name",
      width: 150,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      width: 150,
      sortable: true,
    },
    {
      field: "division",
      headerName: "Division",
      width: 150,
      sortable: true,
      userType: ["INDIVIDUAL"],
      tab: [],
    },
    {
      field: "allocateDivision",
      headerName: "Allocate Forest Division",
      width: 200,
      sortable: false,
      userType: ["INDIVIDUAL"],
      tab: [],
    },
    {
      field: "totalArea",
      headerName: "Net Planned Area(Ha)",
      width: 150,
      sortable: false,
    },
    {
      field: "ecoRestorationAmount",
      headerName: "Eco-Restoration Cost(₹)",
      width: 200,
      sortable: false,
    },
    {
      field: "digitalCostEstimate",
      headerName: "Digital Cost Estimate",
      width: 150,
      sortable: false,
      renderCell: RenderEPID,
    },
    {
      field: "viewProgressDetails",
      headerName: "Progress Details",
      width: 150,
      sortable: false,
      renderCell: ViewProgressDetails,
    },
  ];

  const exportToExcelEntity = () => {
    setQueryParams({
      ...queryParams,
      download: true,
    });
  };

  const handleSort = (sortByField) => {
    setQueryParams({
      ...queryParams,
      sortBy: `${sortByField[0]?.field}:${sortByField[0]?.sort.toUpperCase()}`,
    });
  };

  return (
    <DashboardWrapper
      title="Progress Details"
      buttonIcon="/assets/svg/roundPlusIcon.svg"
      buttonText=""
      onButtonClick={() => {}}
      showBtn={!!tableRows.length}
    >
      <Box>
        <Box sx={{}}>
          <Box sx={{ fontSize: "20px", fontWeight: "500" }}>
            {`Total digital cost estimate received:  ${totalCostEstimateReceived}`}
          </Box>

          <Box sx={{ fontSize: "20px", fontWeight: "500" }}>
            {`Total progress details received:  ${totalProgressDetailReceived}`}
          </Box>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            gap: "2%",
            padding: "10px 1%",
            background: "white",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        >
          <SearchBar
            getText={(e) => {
              setQueryParams((a) => {
                return { ...queryParams, searchText: `${e}` };
              });
            }}
            customStyles={{
              wrapper: {
                width: "80%",
              },
            }}
          />
          <Box sx={{ display: "flex", gap: "8px" }}>
            <MultiSelect
              options={states}
              onChange={(e: any) => {
                setFilterKey((prevFilter) => {
                  const newState = {
                    ...prevFilter,
                    stateIds: e.length ? e : null,
                  };

                  setQueryParams({
                    ...queryParams,
                    stateIds: newState?.stateIds,
                  });

                  return newState;
                });
              }}
            />
          </Box>
          <Button onClick={exportToExcelEntity} text={"Export"} />
        </Box>
        {/* Data Grid */}
        {isLoading || isFetching ? (
          <Box {...styles("flexContainer")}>
            <CircularProgress />
          </Box>
        ) : tableRows.length > 0 ? (
          <DataGrid
            rows={tableRows}
            columns={tableColumns}
            rowCount={tableRows.length}
            rowsPerPageOptions={[10]}
            sortingMode="server"
            onSortModelChange={handleSort}
            paginationMode="client"
            pageSize={50}
            columnBuffer={20}
            loading={isLoading}
            getRowId={(row) => `${row.id}-${row.userName}`}
          />
        ) : (
          <EmptyView
            heading="No Entry Found"
            subheading=""
            onClick={() => {}}
          />
        )}
      </Box>
    </DashboardWrapper>
  );
};

export default ProgressDetails;
