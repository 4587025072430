import { Box } from "@mui/material";
import { AxiosError, AxiosResponse } from "axios";
import { useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DashboardWrapper from "src/components/common/DashboardWrapper";
import DataGrid from "src/components/common/DataGrid";
import EmptyView from "src/components/common/EmptyView";
import { Button } from "src/components/common/FormComponents/Buttons";
import HorizontalTabs from "src/components/common/HorizontalTabs";

import { useForm } from "react-hook-form";
import {
  getAllRegistration,
  getCostEstimateDetails,
  getLandDetailsById,
  putCostEstimateQueryDetails,
} from "src/utils/api/dashboard";

import defaultStyles, { StylesClasses } from "./styles";
import { path } from "src/constants/path";
import SearchBar from "src/components/common/SearchField";
import { useAppSelector } from "src/redux/store";
import AutoComplete from "src/components/common/FormComponents/AutoComplete";
import errorMessage from "src/constants/errorMessage";

import { getStyles } from "src/styles/theme";

import ViewForm from "src/components/common/ViewForm";
import {
  CostEstimateDetailsTable,
  DashBoardTable,
} from "src/components/common/DashBoardTable/costEstimateDetails";
import MultiSelect from "src/components/common/MultiSelect";
import { getStates } from "src/utils/api/roles";
import printJS from "print-js";
import { useReactToPrint } from "react-to-print";
import { formatToIndianCurrency } from "src/utils/helper";
import { CostEstimateSummary } from "src/components/common/DashBoardTable/costEstimateSummary";
import { SeedlingTable } from "src/components/common/DashBoardTable/seedlingTable";
import ViewImages from "src/components/common/ViewImage";
import { Input } from "src/components/common/FormComponents";
import { SuccessModal } from "src/components/common/modal";

const CostEstimateDetails = () => {
  const componentRef = useRef();

  const styles = getStyles<StylesClasses>(defaultStyles);

  const [searchParams] = useSearchParams();

  const landOwnershipEpid = searchParams.get("id");

  const [yearTableRows, setYearTableRows] = useState({});

  const [yearSummaryTableRows, setYearSummaryTableRows] = useState([]);
  const [seedlingTableData, setSeedlingTableData] = useState([]);

  const [states, setStates] = useState<any>([]);

  const userDetails = useAppSelector((state: any) => state.root.user);
  const role = userDetails?.data?.role;

  const [queryParams, setQueryParams] = useState<{
    offset: number;
    limit: number;
    download: boolean;
    sortBy: string;
    searchText: string;
    landOwnershipEpid: string;
    stateIds: string;
    status: string;
  }>({
    offset: 0,
    limit: 300,
    download: false,
    sortBy: "updated_at:DESC",
    searchText: "",
    landOwnershipEpid: "",
    stateIds: "",
    status: "APPROVED",
  });

  const [progressQueryParams, setProgressQueryParams] = useState<{
    landOwnershipEpid: string;
  }>({
    landOwnershipEpid: "",
  });

  const [filterKey, setFilterKey] = useState({
    stateIds: null,
  });

  const { data: projectDetails } = useQuery(
    ["fundFlow", queryParams],
    () =>
      getAllRegistration({
        ...queryParams,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        console.log(res?.data?.rows);
      },

      onError: (err: AxiosError) => {},
      refetchOnMount: true,
      enabled: !!queryParams.stateIds,
    }
  );

  const { data: userData } = useQuery(
    ["userDetails"],
    () => getLandDetailsById(landOwnershipEpid),
    {
      onSuccess: (res: AxiosResponse) => {},
      onError: (req: AxiosError) => {},
      enabled: !!landOwnershipEpid,
      refetchOnMount: true,
    }
  );

  const epiddata = userData?.data;

  useQuery(["getStatesType"], () => getStates(), {
    onSuccess: (data) => {
      setStates(data.data);
    },
    refetchOnMount: true,
  });

  const mapYearData = (yearTargets) => {
    return yearTargets?.map((row) => ({
      data: row,
      id: row.id,
      sno: row.serialNumber,
      year: row?.year,
      costEstimateID: row?.landOwnershipEpid,
      mainComponent: row?.indicator?.name,
      subComponent: row?.activity?.name,
      perticularOfItem: row?.perticularOfItem,
      unit: row?.unit,
      costPerUnit: row?.costPerUnit,
      numberOfUnits: row?.numberOfUnits,
      costRequired: row?.costRequired,
    }));
  };

  const {
    isLoading,
    data: targetData,
    refetch,
  } = useQuery(
    ["costEstimateDetails", landOwnershipEpid],
    () =>
      getCostEstimateDetails({
        landOwnershipEpid,
      }),
    {
      onSuccess: (res: AxiosResponse) => {
        const yearData = {
          firstYear: mapYearData(res?.data?.data?.firstYearTargets),
          secondYear: mapYearData(res?.data?.data?.secondYearTargets),
          thirdYear: mapYearData(res?.data?.data?.thirdYearTargets),
          fourthYear: mapYearData(res?.data?.data?.fourthYearTargets),
          fifthYear: mapYearData(res?.data?.data?.fifthYearTargets),
          sixthYear: mapYearData(res?.data?.data?.sixthYearTargets),
          seventhYear: mapYearData(res?.data?.data?.seventhYearTargets),
          eighthYear: mapYearData(res?.data?.data?.eighthYearTargets),
          ninthYear: mapYearData(res?.data?.data?.ninthYearTargets),
          tenthYear: mapYearData(res?.data?.data?.tenthYearTargets),
          other: mapYearData(res?.data?.data?.otherNecessaryProvisions),
        };
        setYearSummaryTableRows([
          res?.data?.data?.firstYearTotalCostRequired,
          res?.data?.data?.secondYearTotalCostRequired,
          res?.data?.data?.thirdYearTotalCostRequired,
          res?.data?.data?.fourthYearTotalCostRequired,
          res?.data?.data?.fifthYearTotalCostRequired,
          res?.data?.data?.sixthYearTotalCostRequired,
          res?.data?.data?.seventhYearTotalCostRequired,
          res?.data?.data?.eighthYearTotalCostRequired,
          res?.data?.data?.ninthYearTotalCostRequired,
          res?.data?.data?.tenthYearTotalCostRequired,
          res?.data?.data?.otherNecessaryProvisionsTotalCostRequired,
          res?.data?.data?.totalCostRequired,
        ]);
        setSeedlingTableData(res?.data?.data?.seedlings);
        setYearTableRows(yearData);
      },
      onError: (err: AxiosError) => {},
      refetchOnMount: true,
    }
  );

  const {
    control,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm<any>({
    mode: "onChange",
    defaultValues: {
      landOwnershipEpid: "",
      cedQuery: "",
    },
  });

  const commonTableHeaders = [
    "Year",
    "Main Component",
    "Sub Component",
    "Particulars of item of works",
    "Unit",
    "No of Unit Required",
    "Cost Required(Rs.)",
  ];

  const commonSummaryHeaders = ["Year", "Cost Required"];
  const commonSeedlingHeader = [
    "Common Name",
    "Botanical Name",
    "No of Seedlings",
  ];

  const renderYearlyTables = () => {
    return Object.keys(yearTableRows).map((yearKey, index) => {
      const tableData: DashBoardTable = {
        topHeader: commonTableHeaders,
        data: yearTableRows[yearKey],
      };

      if (yearTableRows[yearKey]?.length > 0) {
        return (
          <Box key={yearKey} sx={{ marginBottom: "30px", marginTop: "30px" }}>
            <CostEstimateDetailsTable {...tableData} />
          </Box>
        );
      }
      return null;
    });
  };

  const renderSummaryTable = () => {
    const summaryData: DashBoardTable = {
      topHeader: commonSummaryHeaders,
      data: yearSummaryTableRows,
    };
    const seedlingData: DashBoardTable = {
      topHeader: commonSeedlingHeader,
      data: seedlingTableData,
    };

    if (yearSummaryTableRows) {
      return (
        <Box
          sx={{
            marginBottom: "30px",
            marginTop: "30px",
            display: "flex",
            gap: "20px",
          }}
        >
          <CostEstimateSummary
            {...summaryData}
            status={targetData?.data?.data?.status}
          />
          <SeedlingTable {...seedlingData} />
        </Box>
      );
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: "@media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const { mutate: mutateCostEstimateQuery } = useMutation(
    ["deselect"],
    ({ data }: any) => putCostEstimateQueryDetails(data),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const [openModal, setOpenModal] = useState({
    open: false,
    heading: "Query sent to IO",
    type: "",
  });

  const handleConfirmationModalClose = () => {
    setOpenModal({ ...openModal, open: false });
    setValue("cedQuery", "");
  };

  const handleBackToIoButtonClick = () => {
    setOpenModal({
      open: true,
      heading: "Query sent to IO",
      type: "success",
    });
    mutateCostEstimateQuery({
      data: {
        landOwnershipEpid: landOwnershipEpid,
        status: "UNDER_QUERY",
        remark: getValues("cedQuery"),
      },
    });
  };

  return (
    <DashboardWrapper
      title="Digital Cost Estimate"
      buttonText="Print"
      onButtonClick={handlePrint}
      showBtn={true}
      showBackBtn
    >
      <Box>
        <Box ref={componentRef} id="printable-content">
          {Object.values(yearTableRows).flat().length > 0 ? (
            <>
              <Box
                sx={{
                  fontSize: "20px",
                  textAlign: "center",
                  marginTop: "26px",
                  fontWeight: "bold",
                }}
              >
                Cost Estimate for eco-restoration of degraded forest lands under
                the Green Credit Programme
              </Box>
              <Box {...styles("formWrapper")}>
                <Box sx={{ width: "40%" }}>
                  <ViewForm
                    label={"Registration No"}
                    value={epiddata?.projectDetail?.registrationNo}
                  />
                  <ViewForm
                    label={"EPID"}
                    value={
                      targetData?.data?.data?.plantationPlanning
                        ?.landOwnershipEpid
                    }
                  />
                  <ViewForm
                    label={"State"}
                    value={epiddata?.projectDetail?.address?.stateDetail?.name}
                  />
                  <ViewForm
                    label={"District"}
                    value={
                      epiddata?.projectDetail?.address?.districtDetail?.name
                    }
                  />
                  <ViewForm
                    label={"Division"}
                    value={epiddata?.projectDetail?.landSpecification?.division}
                  />
                  <ViewForm
                    label={"Range"}
                    value={epiddata?.projectDetail?.landSpecification?.range}
                  />
                  <ViewForm
                    label={"Beat"}
                    value={epiddata?.projectDetail?.landSpecification?.beat}
                  />
                </Box>
                <Box sx={{ width: "40%" }}>
                  <ViewForm
                    label={"Total Area(ha)"}
                    value={
                      epiddata?.projectDetail?.landSpecification?.totalArea
                    }
                  />
                  <ViewForm
                    label={"Net Plantation Area(ha)"}
                    value={epiddata?.netPlantationArea}
                  />
                  <ViewForm
                    label={"Eco-Restoration Cost"}
                    value={formatToIndianCurrency(epiddata?.demandNote)}
                  />
                  <ViewForm
                    label={"Sapling spacing(m X m)"}
                    value={`${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingX} X ${targetData?.data?.data?.plantationPlanning?.treeSeedlingPacingY}`}
                  />
                  <ViewForm
                    label={"Pit Size"}
                    value={targetData?.data?.data?.plantationPlanning?.pitSize}
                  />
                  <ViewForm
                    label={"Total No. of Saplings"}
                    value={
                      targetData?.data?.data?.plantationPlanning
                        ?.totalNumberOfSeedlingToBePlanted
                    }
                  />
                </Box>
                <Box sx={{ width: "30%" }}>
                  <Box
                    sx={{
                      fontSize: "17px",
                      fontWeight: "bold",
                    }}
                  >
                    Technically sanctioned/approved cost estimate
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "20px",
                    }}
                  >
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={epiddata?.paymentBreakdownPdfImage}
                        label={"PDF"}
                      />
                    </Box>
                    <Box {...styles("documentGroup")}>
                      <ViewImages
                        fileUrl={epiddata?.paymentBreakdownImage}
                        label={"Ms Excel"}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              {renderSummaryTable()}
              {renderYearlyTables()}
            </>
          ) : (
            <EmptyView
              heading="Cost Estimate Details have not been added for this project."
              subheading=""
              onClick={() => {}}
            />
          )}
        </Box>
      </Box>

      {targetData?.data?.data?.status !== "DRAFT" &&
        role !== "Approver-view-only" && (
          <>
            <Input
              name="cedQuery"
              label="Query"
              control={control}
              errors={errors}
              multiline
              height="360px"
              customStyles={{
                input: {
                  height: "auto",
                  fontFamily: "Poppins",
                  width: { sm: "100%", lg: "80%" },
                },
              }}
              required={true}
            />
            <Button
              text="Back to IO"
              onClick={() => handleBackToIoButtonClick()}
              customStyles={{ root: { mt: "20px" } }}
              disabled={watch("cedQuery")?.length === 0}
            />
          </>
        )}

      <SuccessModal
        open={openModal.open}
        heading={openModal.heading}
        type={openModal.type}
        handleModalClose={() => handleConfirmationModalClose()}
      ></SuccessModal>
    </DashboardWrapper>
  );
};

export default CostEstimateDetails;
